<template>
  <div class="flex w-full h-full min-h-screen  flex-col md:flex-row">
    <div class="w-full md:w-60 flex-shrink-0 bg-gradient-to-br from-yellow to-yellow_light md:pb-16">
      <LayoutAppMenu />
    </div>
    <div class="flex-grow bg-gradient-to-br from-black to-gray pb-16">
      <slot />
    </div>
    <div class="footer z-50 text-center w-full fixed bottom-0 right-0 bg-gradient-to-r from-yellow_light to-yellow">
      <div class="flex justify-center items-center gap-3">
        <a href="https://revolucnicviceni.cz/" target="_blank">
          <img
            src="/favicons/android-chrome-512x512.png"
            class="max-w-full h-10 md:h-12 mx-auto my-2"
            alt="Sixpack Logo"
          >
        </a>
        <div class="md:hidden">
          <client-only>
            <MoleculeUserProgressBar />
          </client-only>
        </div>
      </div>
    </div>
    <div id="teleports" />
  </div>
</template>

<script setup lang="ts">
import { useLocalePath } from '#imports'

onMounted(() => {
  const authStore = useAuthenticationStore()
  if (!authStore.isAuthenticated) {
    const localePath = useLocalePath()
    const router = useRouter()
    const path = localePath({ name: 'login' })
    router.push(path)
  }
})
</script>

<style lang="postcss" scoped>
.footer {
  @media(min-width: 768px) {
    width: calc(100% - 15rem);
  }
}
</style>

<script setup lang="ts">
const authStore = useAuthenticationStore()
const { dailyProgress, dailyTarget } = storeToRefs(authStore)
</script>

<template>
  <span class="text-sm text-center font-bold">Dnes odcvičeno {{ dailyProgress }}/{{ dailyTarget }} minut</span>
  <progress
    class="rounded overflow-hidden h-2 appearance-none w-full"
    :value="dailyProgress"
    :max="dailyTarget"
  />
</template>

<style lang="postcss">
progress {
  &::-webkit-progress-bar {
    @apply bg-stone-200;
  }
  &::-moz-progress-bar {
    @apply bg-stone-200;
  }

  &::-webkit-progress-value {
    -webkit-appearance: none;
    @apply bg-violet-700;
  }
}
</style>
